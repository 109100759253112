/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

const { UIProvider } = require('context/ui');

registerLinkResolver(linkResolver);

exports.onClientEntry = () => {
  require('intersection-observer');
}

exports.wrapRootElement = ({ element }) => (
  <UIProvider>
    {element}
  </UIProvider>
)
// page transitions
exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const transitionDelay = 450;

    // need to return out of this if is article navigation also
    if (location.hash) return; // don't scroll if its a hash link
    if (location.action === 'PUSH') {

      window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay,
      );
  };
  return false;
};
