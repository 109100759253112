
exports.linkResolver = function linkResolver(doc) {

  if (doc.link_type) {
    switch (doc.link_type) {
      case 'Web':
        return doc.url;
      case 'Media':
        return doc.url;
      default:
        if (doc.url) {
          return doc.url;
        }
    }
  }

  if (doc.type) {
    switch (doc.type) {
      case 'page_content':
        return `/page/${doc.uid}`;
      case 'beer':
        return `/beers/${doc.uid}`;
      default:
        if (doc.uid) {
          return `/${doc.uid}`;
        }
        return `/${doc.type}`;
    }
  }

  return '/';
};
