import * as React from 'react';

import { Link } from 'components/link/Link';
import { Image } from 'components/image/Image';

import s from './Header.scss';

interface IHeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: IHeaderProps) => (
  <header className={s.header}>
    <Link
      to="/"
      className={s.header__logo}
    >
      <Image
        className={s.header__logoImg}
        src={require('assets/images/logo.png')}
      />
    </Link>
    <div className={s.header__container}>
      <div className={s.header__content}>
        <div className={s.header__navigation}>
          {children}
        </div>
      </div>
    </div>
  </header>
);
