import React from 'react';

import { useResize } from 'hooks/use-resize';

interface IProps {
  children: React.ReactNode;
}

export const UIContext = React.createContext({});

export const UIProvider = ({ children }: IProps) => {

  const [navOpen, setNavOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [headerTheme, setHeaderTheme] = React.useState('dark');
  const [canTransition, setCanTransition] = React.useState(true);
  const [footerVisible, setFooterVisible] = React.useState(true);
  const [headerVisible, setHeaderVisible] = React.useState(true);

  const menuOpen = navOpen || searchOpen;

  const resize = useResize();
  const { isMobile, isDesktop } = resize;

  const closeAllMenus = () => {
    setSearchOpen(false);
    setNavOpen(false);
  };

  return (
    <UIContext.Provider
      value={{
        isMobile,
        isDesktop,
        navOpen,
        setNavOpen,
        searchOpen,
        setSearchOpen,
        menuOpen,
        closeAllMenus,
        headerTheme,
        setHeaderTheme,
        canTransition,
        setCanTransition,
        footerVisible,
        setFooterVisible,
        headerVisible,
        setHeaderVisible,
      }}
    >
      {children}
    </UIContext.Provider>
  );

};

export const withUIContext = (Component: any) =>
  (props: any) => (
    <UIContext.Consumer>
      {(context) => <Component {...props} ui={context} />}
    </UIContext.Consumer>
  );
