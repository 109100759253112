module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"malbygg","previews":true,"path":"/preview","pages":[{"type":"Beer","match":"/beers/:uid","path":"/beer-preview","component":"/opt/build/repo/src/templates/beer.tsx"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/app-layout/AppLayout.tsx"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
