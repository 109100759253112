import * as React from "react";
import Helmet from "react-helmet";

import Logo from "assets/svg/ueno-logo.svg";
import Dribbble from "assets/svg/dribbble.svg";
import Twitter from "assets/svg/twitter.svg";
import Github from "assets/svg/github.svg";
import Instagram from "assets/svg/instagram.svg";
import Facebook from "assets/svg/facebook.svg";
import Linkedin from "assets/svg/linkedin.svg";

import { helmet } from "utils/helmet";
import { Header } from "components/header/Header";
import { SocialPane } from "components/social-pane/SocialPane";
import { Link as HeaderLink } from "components/header/Link";
import { Footer } from "components/footer/Footer";
import { Devtools } from "components/devtools/Devtools";
import { PageTransition } from "components/page-transition/PageTransition";

// import Cursor from 'components/cursor/Cursor';

import s from "./AppLayout.scss";

interface IAppLayoutProps {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
}

const isDev = process.env.NODE_ENV === "development";

export default ({ children, location }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      <HeaderLink name="Beers" to="/beers" />
      <HeaderLink name="Taproom" to="/taproom" />
      <HeaderLink name="Tour" to="/tour" />
      <HeaderLink name="Store" to="/store" />
      <HeaderLink name="About" to="/about" />
    </Header>

    <SocialPane />

    <PageTransition location={location}>{children}</PageTransition>

    {isDev && <Devtools />}
  </div>
);
