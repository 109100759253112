import * as React from "react";

import { Link } from "components/link/Link";

import IconTwitter from "assets/svg/icon-twitter.svg";
import IconInstagram from "assets/svg/icon-instagram.svg";
import IconFacebook from "assets/svg/icon-facebook.svg";

import s from "./SocialPane.scss";

interface ISocialPaneProps {
  children?: React.ReactNode;
}

export const SocialPane = ({ children }: ISocialPaneProps) => (
  <div className={s.socialPane}>
    <ul className={s.socialPane__list}>
      <li className={s.socialPane__item}>
        <a
          href="http://twitter.com/malbygg"
          className={s.socialPane__link}
          target="_blank"
          title="Malbygg on Twitter"
        >
          <IconTwitter className={s.socialPane__icon} />
        </a>
      </li>
      <li className={s.socialPane__item}>
        <a
          href="http://instagram.com/malbygg"
          className={s.socialPane__link}
          target="_blank"
          title="Malbygg on Instagram"
        >
          <IconInstagram className={s.socialPane__icon} />
        </a>
      </li>
      <li className={s.socialPane__item}>
        <a
          href="https://www.facebook.com/malbyggbrugghus"
          className={s.socialPane__link}
          target="_blank"
          title="Malbygg on Facebook"
        >
          <IconFacebook className={s.socialPane__icon} />
        </a>
      </li>
    </ul>
  </div>
);
