// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/opt/build/repo/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-beer-tsx": () => import("/opt/build/repo/src/templates/beer.tsx" /* webpackChunkName: "component---src-templates-beer-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bars-tsx": () => import("/opt/build/repo/src/pages/bars.tsx" /* webpackChunkName: "component---src-pages-bars-tsx" */),
  "component---src-pages-beers-tsx": () => import("/opt/build/repo/src/pages/beers.tsx" /* webpackChunkName: "component---src-pages-beers-tsx" */),
  "component---src-pages-galaxy-tsx": () => import("/opt/build/repo/src/pages/galaxy.tsx" /* webpackChunkName: "component---src-pages-galaxy-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-store-tsx": () => import("/opt/build/repo/src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-taproom-tsx": () => import("/opt/build/repo/src/pages/taproom.tsx" /* webpackChunkName: "component---src-pages-taproom-tsx" */),
  "component---src-pages-tour-tsx": () => import("/opt/build/repo/src/pages/tour.tsx" /* webpackChunkName: "component---src-pages-tour-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

